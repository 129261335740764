import { FirmType, SettingEntityType } from '../services/gateway-api-client/dtos/infrastructure.enums';
import { FirmType as ProposalFirmType } from '../services/gateway-api-client/dtos/proposal.enums';
import { ProposalCaseType } from '../services/gateway-api-client/dtos/proposal.enums';

export const firmTypeToSettingEntityTypeMap: Map<FirmType, SettingEntityType> = new Map([
  [FirmType.DtccDistributor, SettingEntityType.DtccDistributor],
  [FirmType.Imo, SettingEntityType.Imo],
  [FirmType.Oid, SettingEntityType.Oid]
]);

export const firmTypeToProposalFirmTypeMap: Map<FirmType, ProposalFirmType> = new Map([
  [FirmType.DtccDistributor, ProposalFirmType.DtccDistributor],
  [FirmType.Imo, ProposalFirmType.Imo],
  [FirmType.Oid, ProposalFirmType.Oid]
]);

export const firmTypeToProposalCaseTypeMap: Map<FirmType, ProposalCaseType> = new Map([
  [FirmType.Imo, ProposalCaseType.Imo],
  [FirmType.Oid, ProposalCaseType.Oid]
]);
